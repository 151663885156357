import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import i18n from 'src/i18n';

import PaperIcon from 'src/assets/PaperIcon.svg';

import globalSegmentModalViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
export default class SegmentModal extends React.Component {
  render() {
    const { segment, isOpen, onClose } = globalSegmentModalViewModel;
    return (
      <Modal
        open={isOpen}
        onCancel={onClose}
        footer={null}
        width={600}
        closable={false}
        centered
        styles={{
          content: {
            padding: 0
          }
        }}
      >
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.leftContainer}>
              <img src={PaperIcon} alt="PaperIcon" />
              <div className={styles.title}>
                {i18n.t('topic_card_view_more_segment')}
              </div>
            </div>
            <div
              className={styles.rightContainer}
              onClick={onClose}
            >
              <CloseOutlined />
              <div className={styles.closeText}>
                {i18n.t('topic_card_view_more_segment_modal_close')}
              </div>
            </div>
          </div>
          {
            segment.length === 0
              ? (
                <div className={styles.emptyKeyword}>
                  <div className={styles.emptyText}>
                    {i18n.t('no_segment_data')}
                  </div>
                </div>
              )
              : (
                <div className={styles.keywordContainer}>
                  {segment.slice(0, 100).map((keyword) => (
                    <div
                      className={styles.keyword}
                      key={keyword}
                    >
                      {keyword}
                    </div>
                  ))}
                  {segment.length > 100 && (
                    <div>
                      ...
                    </div>
                  )}
                </div>
              )
          }
        </div>
      </Modal>
    );
  }
}
