import React from 'react';
import { Modal, Button } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import downloadConfirmModalViewModel from './viewModel';

import i18n from '../../i18n';
import styles from './styles.module.scss';

@observer
class DownloadConfirmModal extends React.Component {
  render() {

    return (
      <Modal
        open={downloadConfirmModalViewModel.isOpen}
        onCancel={downloadConfirmModalViewModel.onClose}
        styles={{
          content: {
            padding: 0
          }
        }}
        centered
        closable={false}
        footer={null}
        width={600}
      >
        <div className={styles.downloadModalContainer}>
          <div className={styles.downloadModalTitle}>
            {i18n.t('download_topic_modal_title')}
          </div>
          <div className={styles.downloadModalContent}>
            {i18n.t('download_topic_modal_desc')}
          </div>
          <div className={styles.downloadModalActions}>
            <Button
              className={styles.downloadBtn}
              onClick={downloadConfirmModalViewModel.onClose}
            >
              {i18n.t('download_topic_modal_cancel')}
            </Button>
            <Button
              className={clsx(styles.downloadBtn, styles.downloadBtnPrimary)}
              onClick={downloadConfirmModalViewModel.onAgree}
              type="primary"
            >
              {i18n.t('download_topic_modal_agree')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default DownloadConfirmModal;
