import { makeObservable, observable, action, runInAction } from 'mobx';
import { message } from 'antd';

import optionsStore from 'src/stores/optionsStore';
import OpinionService from '../../services/opinion';

/**
 * @description 單篇文章斷詞 Modal
 * @property {Segment[]} segment
 * @property {boolean} isOpen
 */

/**
 * @typedef {Object} Segment
 * @property {string} id
 * @property {string} name
 * @property {number} count
 * @property {number} score
 */

class SegmentModalViewModel {
  @observable segment = [];
  @observable isOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action onOpen = async (vocKey) => {
    try {
      if (optionsStore.isInQueue('segment')) {
        return;
      }
      optionsStore.setLoading('segment');
      const segment = await OpinionService.getSegment(vocKey);
      runInAction(() => {
        this.segment = segment.map((item) => {
          return item.name;
        });
        this.isOpen = true;
      });
    } catch (error) {
      console.log(error);
      message.error('取得斷詞失敗，請稍後再試');
    } finally {
      optionsStore.setCompleted('segment');
    }
  };

  @action onClose = () => {
    this.segment = [];
    this.isOpen = false;
  };
}

const globalSegmentModalViewModel = new SegmentModalViewModel();

export default globalSegmentModalViewModel;
