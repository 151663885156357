import { request, getHost, getHeaders } from './utils';

/**
 * 發送下載提醒
 * @param {SendDownloadAlertData} data
 */
/**
 * @typedef {Object} SendDownloadAlertData
 * @property {string} type 下載類型: opinion,topic,chart
 * @property {number} topicsCount 下載數量
 * @property {string} date 起迄時間
 * @property {FilterContent} filterContent 篩選條件
 */
/**
 * @typedef {Object} FilterContent
 * @property {string} path 圖表路徑
 *
 * @property {string} packageName 數據包名稱
 * @property {string} postType 文章類型
 * @property {string} womType 聲量類型
 * @property {string[]} category 來源類型
 * @property {string[]} website 來源網站
 * @property {string[]} channel 來源頻道
 * @property {string} featureCategory 特性群組
 * @property {string} feature 特性關鍵字
 * @property {boolean} industry 行業
 * @property {string[]} brand 品牌
 * @property {string[]} series 系列
 * @property {string[]} product 產品
 * @property {string[]} sentiment 情緒
 * @property {string} keyword 關鍵字
 */
export const sendDownloadAlert = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/download/alert`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
