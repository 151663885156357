import { request, getHost, getHeaders, getCacheHeaders } from './utils';

export const getResources = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/opinion/post`,
    headers: getCacheHeaders(),
    data
  };
  return request(options);
};

export const getOpinions = (data, params = {}) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/opinion/post`,
    headers: getCacheHeaders(),
    params: {
      posts: true,
      ...params
    },
    data
  };
  return request(options);
};

export const getDownloadOpinions = (data, params = {}) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/opinion/post`,
    headers: getCacheHeaders(),
    params: {
      posts: true,
      all: true,
      ...params
    },
    data
  };
  return request(options);
};

export const getOpinion = (vocKey, page) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/opinion/post/${vocKey}`,
    headers: getCacheHeaders(),
    params: {
      page
    }
  };
  return request(options);
};

export const getOpinionChart = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/opinion/post/summary`,
    headers: getCacheHeaders(),
    data
  };
  return request(options);
};

// > 單篇文章斷詞api

export const getSegment = (vocKey) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/post/${vocKey}/segment`,
    headers: getCacheHeaders()
  };
  return request(options);
};
