import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { decode } from 'js-base64';
import * as xlsx from 'xlsx';
import { POST_TYPE_TC, E_WOM_TYPE, OPINION_CSV_TITLE_ROW } from 'src/consts';
import OpinionService from 'src/services/opinion';
import SearchTopicCardViewModel from 'src/components/SearchTopicCard/viewModel';
import DownloadService from 'src/services/download';
import UserService from 'src/services/user';

export default class TopicsResultPageViewModel {
  @observable chartName = '';

  // > normal content
  @observable keyword = '';
  @observable gte = '';
  @observable lte = '';
  @observable postType = 'all';
  @observable womType = 'all';

  @observable womCount = 0;

  // > source related
  @observable category = [];
  @observable website = {};
  @observable channel = {};

  // > keyword chart.
  @observable term = {
    name: '',
    score: 0
  };

  // > select related
  @observable sortType = 'date';
  @observable sentimentType = [-1, 0, 1];

  // > for single sentiment chart use, not allow to adjust sentimentType
  @observable disabledSentiment = false;

  // > res related
  @observable topicList = [];
  @observable totalCount = 0;
  @observable page = 1;

  @observable isLoading = false;

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  // > add 241205 -> should loading user profile
  @observable userType = 'customer';

  @computed get totalPage() {
    if (this.topicList.length === 0) {
      return 0;
    }
    return Math.ceil(this.totalCount / 50);
  }

  @computed get completedDateString() {
    return `日期：${this.gte} ~ ${this.lte}`;
  }

  @computed get typeText() {
    return `文章類型：${POST_TYPE_TC[this.postType]} | 聲量類型：${E_WOM_TYPE[this.womType]}`;
  }

  constructor() {
    makeObservable(this);
  }

  @action didMount = async () => {
    this.deserialize();

    await this.getUserType();
    await this.getTopics();
  };

  // > 241205 -> should loading user profile
  @action getUserType = async () => {
    try {
      const userInfo = await UserService.myProfile();
      this.userType = userInfo.permission;
    } catch (error) {
      console.log(error);
    }
  };

  @action deserialize = async () => {
    const params = new URL(window.location).searchParams;
    const data = params.get('result').trim();
    const decodeItem = decode(data.trim());
    const result = JSON.parse(decodeItem);

    const {
      keyword,
      chart,
      date,
      postType,
      womType,
      category,
      website,
      channel,
      term,
      sentiment,
      womCount // > 聲量
    } = result;

    this.keyword = keyword;
    this.chartName = chart.name;
    this.gte = date.gte;
    this.lte = date.lte;
    this.postType = postType ?? 'all';
    this.womType = womType ?? 'all';
    this.category = category ?? [];
    this.website = website ?? {};
    this.channel = channel ?? {};
    this.term = term ?? {};
    this.sentimentType = sentiment && sentiment?.[0]?.id ? sentiment.map((s) => Number(s.id)) : sentiment ?? [-1, 0, 1];
    this.disabledSentiment = !!sentiment;

    // > new add here
    this.womCount = womCount;
  };

  @action onSortTypeChange = (e) => {
    this.sortType = e;

    this.topicList = [];

    this.getTopics();
  };

  @action onSentimentTypeChange = (e) => {
    if (e.length === 0) {
      return;
    }
    this.sentimentType = e;
  };

  @action onSentimentChangeComplete = () => {
    this.topicList = [];

    this.getTopics();
  };

  @action getTopics = async () => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const searchData = {
        keyword: this.keyword,
        date: {
          gte: this.gte,
          lte: this.lte
        },
        ...(this.postType !== 'all' && { postType: this.postType }),
        ...(this.womType !== 'all' && { womType: this.womType }),
        ...((this.category.length === 1 && (!this.website.id && !this.channel.id)) && { category: this.category.map((el) => el.id) }),
        ...(this.website.id && { website: [this.website.id] }),
        ...(this.channel.id && { channel: [this.channel.id] }),
        ...(this.term.name && { term: this.term.name }),
        ...(this.sentimentType.length === 1 && { sentiment: this.sentimentType[0] })
      };

      const searchParams = {
        page: this.page,
        sortBy: this.sortType
      };
      const { post, summary } = await OpinionService.getOpinions(searchData, searchParams);

      runInAction(() => {
        this.totalCount = summary;
        this.topicList.push(...post.map((el) => new SearchTopicCardViewModel(el)));
      });
      // }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action onScrollEnd = async () => {
    this.page += 1;
    await this.getTopics();
  };

  @action onDownloadClick = async () => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.isDownload = true;

    try {
      const searchData = {
        keyword: this.keyword,
        date: {
          gte: this.gte,
          lte: this.lte
        },
        ...(this.postType !== 'all' && { postType: this.postType }),
        ...(this.womType !== 'all' && { womType: this.womType }),
        ...(this.category.length === 1 && { category: this.category.map((el) => el.id) }),
        ...(this.website.length > 0 && { website: this.website.map((el) => el.id) }),
        ...(this.channel.length > 0 && { channel: this.channel.map((el) => el.id) }),
        ...(this.term.name && { term: this.term.name }),
        ...(this.sentimentType.length === 1 && { sentiment: this.sentimentType[0] })
      };
      const searchParams = {
        sortBy: this.sortType
      };

      const { post } = await OpinionService.getDownloadOpinions(searchData, searchParams, this.userType);
      runInAction(() => {
        const data = [...post];
        const header = OPINION_CSV_TITLE_ROW
          // .filter((el) => this.userType !== 'customer' || el.key !== 'content')
          .map((el) => el.key);
        const workSheet = xlsx.utils.json_to_sheet(data, { header });
        xlsx.utils.sheet_add_aoa(
          workSheet,
          [OPINION_CSV_TITLE_ROW
            // .filter((el) => this.userType !== 'customer' || el.key !== 'content')
            .map((el) => el.header)],
          { origin: 'A1' }
        );
        workSheet['!cols'] = [10, 80,
          80,
          // ...(this.userType !== 'customer' ? [80] : []),
          30, 20, 10, 20, 20, 20, 10, 10, 10, 60].map((el) => ({ width: el }));
        const wordbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wordbook, workSheet, '話題列表');
        xlsx.writeFile(wordbook, `${this.keyword}-${this.gte}~${this.lte}.xlsx`);
      });
      if (this.userType === 'customer') {
        await DownloadService.sendDownloadAlert({
          topicsCount: post.length,
          date: `${this.gte}_${this.lte}`,
          type: 'chart',
          filterContent: {
            path: this.chartName,
            keyword: this.keyword,
            ...(this.postType !== 'all' && { postType: this.postType }),
            ...(this.womType !== 'all' && { womType: this.womType }),
            ...(this.category.length === 1 && { category: this.category.map((el) => el.name) }),
            ...(this.website.length > 0 && { website: this.website.map((el) => el.name) }),
            ...(this.channel.length > 0 && { channel: this.channel.map((el) => el.name) }),
            ...(this.term.name && { term: this.term.name }),
            ...(this.sentimentType.length === 1 && { sentiment: [this.sentimentType[0]] })
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isDownload = false;
      });
    }
  };

}
