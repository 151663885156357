import { makeObservable, action, observable, runInAction } from 'mobx';

class DownloadConfirmModalViewModel {
  @observable isOpen = false;
  @observable onAgreeCb = null;

  constructor() {
    makeObservable(this);
  }

  @action onOpen = (cb) => {
    this.isOpen = true;
    this.onAgreeCb = cb;
  };

  @action onClose = () => {
    this.isOpen = false;
    this.onAgreeCb = null;
  };

  @action onAgree = async () => {
    await this.onAgreeCb();

    runInAction(() => {
      this.onClose();
      this.onAgreeCb = null;
    });
  };
}

const downloadConfirmModalViewModel = new DownloadConfirmModalViewModel();

export default downloadConfirmModalViewModel;
