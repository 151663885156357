import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import { CSVLink } from 'react-csv';

import { DownloadOutlined, CloseOutlined, CheckOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Collapse, Empty } from 'antd';
import { POST_TYPE, WORD_OF_MOUTH_TYPE, SORT_TYPE, SENTIMENT_TYPE, TOPICS_CSV_TITLE_ROW } from 'src/consts';
import Select from 'src/components/select';

import FilterIcon from 'src/assets/FilterIcon.svg';

import PackageLevelSelectModal from 'src/components/PackagesLevelSelectModal';
import ListTopicCard from 'src/components/ListTopicCard';
import ScrollTop from 'src/components/ScrollTop';
import InfoHeader from 'src/components/InfoHeader';
import optionsStore from 'src/stores/optionsStore';
import userStore from 'src/stores/userStore';
import downloadConfirmModalViewModel from 'src/components/DownloadConfirmModal/viewModel';

import CustomizeSourceSelect from './components/CustomizeSourceSelect';

import TopicPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class TopicPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new TopicPageViewModel();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <InfoHeader
            breadcrumb={['話題列表']}
            tooltip="請注意：此處勾選的階層設定將影響話題列表內容的範圍。例如：勾選品牌階層的「Apple」、「Samsung」兩項，同時於產品階層勾選「Apple / iPhone 14 Pro」，話題列表將只顯示「Apple / iPhone 14 Pro」的內容。"
            isSinglePeriod
            callback={this.viewModel.onDateSelect}
            multiViewModel={this.viewModel.datePickerViewModel}
          />
          <Collapse
            className={styles.optionsContainer}
            expandIconPosition="end"
            onChange={this.viewModel.onLevelsContainerChange}
          >
            <Collapse.Panel
              header={
                (
                  <div className={styles.levelSelectContainer}>
                    <div className={styles.textContainer}>
                      <div className={styles.text}>
                        {i18n.t('topic_levels_title')}
                      </div>
                      <Button
                        className={styles.filterBtn}
                        type="link"
                        onClick={this.viewModel.onModalOpen}
                      >
                        <div className={styles.btnText}>
                          {i18n.t('topic_levels_setting')}
                        </div>
                        <img src={FilterIcon} alt="" />
                      </Button>
                    </div>
                    <CaretDownOutlined className={clsx(styles.icon, this.viewModel.isLevelsContainerOpened && styles.rotate)} />
                  </div>
                )
              }
              showArrow={false}
            >
              <div className={styles.selectedItemsContainer}>
                <div className={styles.selectedRow}>
                  <div className={styles.selectedItem}>
                    {`${i18n.t('common_brand')}：`}
                  </div>
                  <div className={styles.selectedItem}>
                    {this.viewModel.levelSelectModalViewModel.brandText}
                  </div>
                </div>
                <div className={styles.selectedRow}>
                  <div className={styles.selectedItem}>
                    {`${i18n.t('common_series')}：`}
                  </div>
                  <div className={styles.selectedItem}>
                    {this.viewModel.levelSelectModalViewModel.seriesText}
                  </div>
                </div>
                <div className={styles.selectedRow}>
                  <div className={styles.selectedItem}>
                    {`${i18n.t('common_product')}：`}
                  </div>
                  <div className={styles.selectedItem}>
                    {this.viewModel.levelSelectModalViewModel.productText}
                  </div>
                </div>
              </div>
            </Collapse.Panel>
          </Collapse>

          <div className={styles.resContainer}>
            <div className={styles.sourceSegment}>
              <div
                className={clsx(styles.segmentItem, this.viewModel.filterSource === 'all' && styles.active)}
                onClick={() => this.viewModel.onSourceChange('all')}
              >
                {i18n.t('topic_source_all')}
              </div>
              {
                this.viewModel.category.map((el) => {
                  return (
                    <div
                      key={el.value}
                      className={clsx(styles.segmentItem, this.viewModel.filterSource === el.value && styles.active)}
                      onClick={() => this.viewModel.onSourceChange(el.value)}
                    >
                      {el.label}
                    </div>
                  );
                })
              }
            </div>
            <div className={styles.mainContainer}>
              <div className={styles.filterContainer}>
                <Select
                  placeholder={i18n.t('filter_post_type_all')}
                  options={POST_TYPE}
                  value={this.viewModel.filterPostType}
                  onSelect={this.viewModel.onPostTypeChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                />
                <Select
                  placeholder={i18n.t('filter_word_of_mouth_all')}
                  options={WORD_OF_MOUTH_TYPE}
                  value={this.viewModel.filterWordOfMouth}
                  onSelect={this.viewModel.onWOMChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                />
                <CustomizeSourceSelect
                  title={`來源網站${this.viewModel.showSelectedWebsiteCount}`}
                  viewModel={this.viewModel.websiteSelectViewModel}
                  callback={this.viewModel.onWebsiteChange}
                />

                <CustomizeSourceSelect
                  title={`來源頻道${this.viewModel.showSelectedChannelCount}`}
                  viewModel={this.viewModel.channelSelectViewModel}
                />

                <Button
                  icon={<CheckOutlined />}
                  className={styles.submitBtn}
                  onClick={this.viewModel.onSubmit}
                >
                  {i18n.t('btn_filter_submit')}
                </Button>
                {
                  this.viewModel.isShowActions
                  && (
                    <Button
                      icon={<CloseOutlined />}
                      className={styles.cleanAllBtn}
                      onClick={this.viewModel.cleanAllFilter}
                    >
                      {i18n.t('btn_filter_clean_all')}
                    </Button>
                  )
                }
              </div>
              <div className={clsx(styles.sortContainer, 'TopicSort')}>
                <div className={styles.sorts}>
                  <div className={styles.sortText}>
                    {i18n.t('topic_res_count').replace('%count%', this.viewModel.totalCount)}
                  </div>
                  <Select
                    placeholder={i18n.t('sort_type_title')}
                    options={SORT_TYPE}
                    value={this.viewModel.sortType}
                    onSelect={this.viewModel.onSortTypeChange}
                    popupMatchSelectWidth={false}
                    popupClassName={styles.sortMenu}
                    className={clsx('sortSelect', styles.filterSelect)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  />
                  <Select
                    placeholder={i18n.t('filter_sentiment_title')}
                    options={SENTIMENT_TYPE}
                    value={this.viewModel.sentimentType}
                    onChange={this.viewModel.onSentimentTypeChange}
                    popupMatchSelectWidth={false}
                    popupClassName={styles.sortMenu}
                    className={clsx('sortSelect', styles.filterSelect)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    mode="multiple"
                    onBlur={this.viewModel.onSentimentChangeComplete}
                    removeIcon={null}
                  />

                </div>
                <Button
                  icon={<DownloadOutlined />}
                  className={styles.downloadButton}
                  onClick={() => downloadConfirmModalViewModel.onOpen(this.viewModel.onDownload)}
                >
                  {i18n.t('topic_download')}
                </Button>
                {
                  this.viewModel.isDownload
                  && (
                    <CSVLink
                      className="topicDownload"
                      headers={TOPICS_CSV_TITLE_ROW}
                      data={this.viewModel.downloadList}
                      filename={this.viewModel.downloadFileName}
                      style={{ display: 'none' }}
                    />
                  )
                }

              </div>
              <div
                className={styles.resContainer}
              >
                {
                  this.viewModel.topicList.length > 0
                    ? (
                      <>
                        {
                          this.viewModel.topicList.map((el, index) => <ListTopicCard key={el.matchId} viewModel={el} order={index} userType={userStore.activeUserType} />)
                        }
                      </>
                    )
                    : (
                      <div
                        className={styles.emptyContainer}
                      >
                        <Empty
                          description={optionsStore.isLoading ? '搜尋中，請稍候' : '暫無話題列表，請重新選擇條件'}
                        />
                      </div>
                    )
                }
              </div>
              {
                this.viewModel.activePage < this.viewModel.totalPage
                && (
                  <Waypoint onEnter={this.viewModel.onPaging} />
                )
              }
            </div>

          </div>
        </div>
        <ScrollTop containerName="main" />
        {/* {
          optionsStore.isImgDownload
          && (
            <div className={styles.curtain}>
              <div className={styles.color} />
              <div className={styles.absolute}>
                <img src={Loading} alt="" style={{ borderRadius: 8 }} />
              </div>
            </div>
          )
        } */}
        <PackageLevelSelectModal
          viewModel={this.viewModel.levelSelectModalViewModel}
          callback={this.viewModel.onLevelSelectedSubmit}
        />
      </>
    );
  }
}

TopicPage.propTypes = {

};

TopicPage.defaultProps = {

};


export default TopicPage;
