import API from 'src/apis';
import packageStore from 'src/stores/packageStore';

import SelectedLevelModel from 'src/models/response/SelectedLevel';
import TopicCardModel from 'src/models/response/TopicCard';
import TopicDetailModel from 'src/models/response/TopicDetail';
import TopicDetailCommentsModel from 'src/models/response/TopicDetailComments';
import DownloadTopicsModel from 'src/models/response/DownloadTopics';

export default class TopicService {
  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getTopics(params, data) {
    const { data: resData } = await API.topic.getTopics(TopicService.getPackageId(), params, data);
    const {
      summary,
      result,
      searchId
    } = resData;

    return { summary, list: result.map((el) => TopicCardModel.fromRes({ ...el, searchId, packageId: TopicService.getPackageId() })), searchId };
  }

  static async getDownloadTopics(sid, params, userType) {
    const res = await API.topic.getNextPageTopics(TopicService.getPackageId(), sid, params);

    return DownloadTopicsModel.fromRes(res.data.result, userType);
  }

  static async getDownloadTopicsWithPid(pid, sid, params, userType) {
    const res = await API.topic.getNextPageTopics(pid, sid, params);

    return DownloadTopicsModel.fromRes(res.data.result, userType);
  }


  static async getTopicsWithPid(pid, params, data) {
    const { data: resData } = await API.topic.getTopics(pid, params, data);
    const {
      summary,
      result,
      searchId
    } = resData;

    return { summary, list: result.map((el) => TopicCardModel.fromRes({ ...el, searchId, packageId: pid })), searchId };
  }



  static async getNextPageTopicsWithPid(pid, sid, params) {
    const { data: resData } = await API.topic.getNextPageTopics(pid, sid, params);
    const {
      result,
      searchId
    } = resData;

    return { list: result.map((el) => TopicCardModel.fromRes({ ...el, searchId })), searchId };
  }

  static async getNextPageTopics(sid, params) {
    const { data: resData } = await API.topic.getNextPageTopics(TopicService.getPackageId(), sid, params);
    const {
      result,
      searchId
    } = resData;

    return { list: result.map((el) => TopicCardModel.fromRes({ ...el, searchId, packageId: TopicService.getPackageId() })), searchId };
  }

  static async updateSentiment(pid, data) {
    await API.topic.updateSentiment(pid, data);
  }

  static async getSingleTopic(packageId, vocKey, params) {
    const res = await API.topic.getSingleTopic(packageId, vocKey, params);

    return TopicDetailModel.fromRes(res.data.result);
  }

  static async getSingleTopicPaging(packageId, vocKey, params) {
    const res = await API.topic.getSingleTopic(packageId, vocKey, params);

    return TopicDetailCommentsModel.fromRes(res.data.result, params.page);
  }

  static async getSelectedLevel() {
    const res = await API.topic.getSelectedLevel(TopicService.getPackageId());

    return SelectedLevelModel.fromRes(res.data.result);
  }

  static async updateLevels(data) {
    await API.topic.updateLevels(TopicService.getPackageId(), data);
  }

  static async getSegment(vocKey) {
    const res = await API.topic.getSegment(vocKey);

    return res.data.result;
  }
}
