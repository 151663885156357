import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import * as xlsx from 'xlsx';

import { SOURCE_CATEGORY, TOPICS_CSV_TITLE_ROW } from 'src/consts';

import packageStore from 'src/stores/packageStore';
import SourceService from 'src/services/source';
import optionsStore from 'src/stores/optionsStore';
import TopicService from 'src/services/topic';
import userStore from 'src/stores/userStore';
import DownloadService from 'src/services/download';
import ListTopicCardViewModel from 'src/components/ListTopicCard/viewModel';

import PackageLevelSelectModalViewModel from 'src/components/PackagesLevelSelectModal/viewModel';
import MultiPeriodDatePickerViewModel from 'src/components/MultiPeriodDatePicker/viewModel';

import CheckGroupItemViewModel from './components/CheckGroupItem/viewModel';

import CustomizeSourceSelectViewModel from './components/CustomizeSourceSelect/viewModel';


export default class TopicPageViewModel {
  @observable levelSelectModalViewModel = new PackageLevelSelectModalViewModel();

  @observable topicList = [];

  @observable category = [...SOURCE_CATEGORY];
  @observable filterSource = 'all';
  @observable websiteSelectViewModel = new CustomizeSourceSelectViewModel();
  @observable channelSelectViewModel = new CustomizeSourceSelectViewModel();

  @observable isLevelsContainerOpened = false;
  @observable filterPostType = 'all';
  @observable postTypeCache = 'all';
  @observable filterWordOfMouth = 'all';
  @observable filterWebsite = null;
  @observable filterChannel = null;

  @observable sortType = 'womCount';

  @observable sentimentType = [0, 1, -1];

  @observable totalCount = 0;
  @observable postCount = 0;
  @observable commentCount = 0;

  @observable activePage = 1;

  @observable searchId = '';

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @observable datePickerViewModel = new MultiPeriodDatePickerViewModel('yesterday');

  @computed get isShowActions() {
    return this.filterPostType !== 'all' || this.filterWordOfMouth !== 'all' || this.websiteSelectViewModel.indeterminate || this.channelSelectViewModel.indeterminate;
  }

  @computed get totalPage() {
    if (this.postTypeCache === 'all') {
      return Math.ceil(this.totalCount / 50);
    }

    if (this.postTypeCache === 'post') {
      return Math.ceil(this.postCount / 50);
    }

    return Math.ceil(this.commentCount / 50);
  }

  @computed get needLevelParams() {
    return this.levelSelectModalViewModel.selectedBrands.length > 0 || this.levelSelectModalViewModel.selectedSeries.length > 0 || this.levelSelectModalViewModel.selectedProduct.length > 0;
  }

  @computed get showSelectedWebsiteCount() {
    return this.websiteSelectViewModel.selected.length !== 0 ? `(${this.websiteSelectViewModel.selected.length})` : '';
  }

  @computed get showSelectedChannelCount() {
    return this.channelSelectViewModel.selected.length !== 0 ? `(${this.channelSelectViewModel.selected.length})` : '';
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    if (!packageStore.isLevelProcessing) {
      await this.getSelectedLevel();
      await this.getSource();
      await this.getTopics();

    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        async (boolean) => {
          if (!boolean) {
            await this.getSelectedLevel();
            await this.getSource();
            await this.getTopics();

            once();
          }
        }
      );
    }
  };

  @action getSelectedLevel = async () => {
    try {
      const data = await TopicService.getSelectedLevel();
      runInAction(() => {
        this.levelSelectModalViewModel.setInit(data);

      });
    } catch (error) {
      console.log(error);
    }
  };

  @action getTopics = async () => {
    if (optionsStore.loadingQueue.some((el) => el === 'topics')) {
      return;
    }
    const params = {
      sortBy: this.sortType,
      sentiment: this.sentimentType.join(',')
    };
    const data = {
      date: {
        gte: this.datePickerViewModel.currentDate[0].format('YYYY-MM-DD'),
        lte: this.datePickerViewModel.currentDate[1].format('YYYY-MM-DD')
      },
      ...(this.filterPostType !== 'all' && { postType: this.filterPostType }),
      ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth }),
      ...(this.filterSource !== 'all' && {
        category: [this.filterSource]
      }),
      ...(this.websiteSelectViewModel.selected.length >= 1 && { website: this.websiteSelectViewModel.selected }),
      ...(this.channelSelectViewModel.selected.length >= 1 && { channel: this.channelSelectViewModel.selected }),
      ...(this.needLevelParams && {
        search: {
          ...(this.levelSelectModalViewModel.selectedBrands.length > 0 && { level1: this.levelSelectModalViewModel.selectedBrands }),
          ...(this.levelSelectModalViewModel.selectedSeries.length > 0 && {
            tag: this.levelSelectModalViewModel.selectedSeries.map((el) => ({
              keyId: el.parentId,
              valueId: el.id
            }))
          }),
          ...(this.levelSelectModalViewModel.selectedProduct.length > 0 && { level3: this.levelSelectModalViewModel.selectedProduct })
        }
      })
    };
    optionsStore.setLoading('topics');
    // > 24/04/25 updating
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('topics');
      await packageStore.syncLatest(checkLatest);
      return;
    }
    try {
      const { list, summary, searchId } = await TopicService.getTopics(params, data);

      runInAction(() => {
        const { commentCount, postCount, totalCount } = summary;
        this.totalCount = totalCount;
        this.postCount = postCount;
        this.commentCount = commentCount;

        this.searchId = searchId;

        this.topicList = list.map((el) => new ListTopicCardViewModel(el));

        this.activePage = 1;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('topics');
      });
    }
  };

  @action getSource = async () => {
    if (optionsStore.loadingQueue.some((el) => el === 'source')) {
      return;
    }

    optionsStore.setLoading('source');
    try {
      const data = {
        date: {
          gte: this.datePickerViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: this.datePickerViewModel.currentDate[1].format('YYYY-MM-DD')
        },
        ...(this.filterPostType !== 'all' && { postType: this.filterPostType }),
        ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth }),
        ...(this.needLevelParams && {
          search: {
            ...(this.levelSelectModalViewModel.selectedBrands.length > 0 && { level1: this.levelSelectModalViewModel.selectedBrands }),
            ...(this.levelSelectModalViewModel.selectedSeries.length > 0 && {
              tag: this.levelSelectModalViewModel.selectedSeries.map((el) => ({
                keyId: el.parentId,
                valueId: el.id
              }))
            }),
            ...(this.levelSelectModalViewModel.selectedProduct.length > 0 && { level3: this.levelSelectModalViewModel.selectedProduct })
          }
        })
      };
      const { source } = await SourceService.getPackageSources(data);
      runInAction(() => {
        this.updateSources(source);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('source');
      });
    }
  };

  @action updateSources = (source) => {
    const category = source.map((el) => {
      return new CheckGroupItemViewModel(el);
    });
    const parent = this.filterSource === 'all' ? this.category.map((el) => el.value) : [this.filterSource];
    this.websiteSelectViewModel.updateList(category);
    this.websiteSelectViewModel.updateSelectedParent(parent);
    const websites = [];
    source.forEach((el) => {
      el.children.forEach((w) => {
        websites.push({
          id: w.id,
          name: `${el.name}/${w.name}`,
          children: w.children
        });
      });
    });
    const channel = websites.map((el) => {
      return new CheckGroupItemViewModel(el);
    });

    this.channelSelectViewModel.updateList(channel);
  };

  @action onDateSelect = async () => {
    await this.getSource();
    await this.onSubmit();
  };

  @action onSubmit = async () => {
    this.postTypeCache = this.filterPostType;
    if (optionsStore.loadingQueue.some((el) => el === 'topics')) {
      return;
    }

    const params = {
      sortBy: this.sortType,
      sentiment: this.sentimentType.join(',')
    };
    const data = {
      date: {
        gte: this.datePickerViewModel.currentDate[0].format('YYYY-MM-DD'),
        lte: this.datePickerViewModel.currentDate[1].format('YYYY-MM-DD')
      },
      ...(this.postTypeCache !== 'all' && { postType: this.postTypeCache }),
      ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth }),
      ...(this.filterSource !== 'all' && {
        category: [this.filterSource]
      }),
      ...(this.websiteSelectViewModel.selected.length >= 1 && { website: this.websiteSelectViewModel.selected }),
      ...(this.channelSelectViewModel.selected.length >= 1 && { channel: this.channelSelectViewModel.selected }),
      ...(this.needLevelParams && {
        search: {
          ...(this.levelSelectModalViewModel.selectedBrands.length > 0 && { level1: this.levelSelectModalViewModel.selectedBrands }),
          ...(this.levelSelectModalViewModel.selectedSeries.length > 0 && {
            tag: this.levelSelectModalViewModel.selectedSeries.map((el) => ({
              keyId: el.parentId,
              valueId: el.id
            }))
          }),
          ...(this.levelSelectModalViewModel.selectedProduct.length > 0 && { level3: this.levelSelectModalViewModel.selectedProduct })
        }
      })
    };
    // > add for sync latest package setting (24/04/25)
    optionsStore.setLoading('topics');
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('topics');
      await packageStore.syncLatest(checkLatest);
      return;
    }
    try {
      const { list, summary, searchId } = await TopicService.getTopics(params, data);

      runInAction(() => {
        const { commentCount, postCount, totalCount } = summary;
        this.totalCount = totalCount;
        this.postCount = postCount;
        this.commentCount = commentCount;

        this.searchId = searchId;

        this.topicList = list.map((el) => new ListTopicCardViewModel(el));

        this.activePage = 1;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('topics');
      });
    }
  };

  @action onPaging = async () => {
    if (optionsStore.loadingQueue.some((el) => el === 'paging')) {
      return;
    }

    optionsStore.setLoading('paging');
    try {
      const params = {
        sortBy: this.sortType,
        sentiment: this.sentimentType.join(','),
        page: this.activePage + 1
      };

      const { list } = await TopicService.getNextPageTopics(this.searchId, params);
      runInAction(() => {
        this.activePage += 1;
        this.topicList.push(...list.map((el) => new ListTopicCardViewModel(el)));
      });

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('paging');
      });
    }
  };

  @action onLevelsContainerChange = () => {
    this.isLevelsContainerOpened = !this.isLevelsContainerOpened;
  };

  @action onModalOpen = (e) => {
    e.stopPropagation();

    this.levelSelectModalViewModel.onModalOpen();
  };

  @action onSourceChange = async (e) => {
    this.filterSource = e;
    if (e !== 'all') {
      this.websiteSelectViewModel.selectedParent = [e];
      this.websiteSelectViewModel.onCleanAll();
      this.channelSelectViewModel.selectedParent = [];
      this.channelSelectViewModel.onCleanAll();
    } else {
      this.websiteSelectViewModel.selectedParent = this.category.map((el) => el.value);
      this.websiteSelectViewModel.onCleanAll();
      this.channelSelectViewModel.selectedParent = [];
      this.channelSelectViewModel.onCleanAll();
    }

    await this.getTopics();
  };

  @action onWebsiteChange = (e) => {
    this.channelSelectViewModel.selectedParent = e;
  };

  @action onPostTypeChange = (e) => {
    this.filterPostType = e;
  };

  @action onWOMChange = (e) => {
    this.filterWordOfMouth = e;
  };

  @action onSortTypeChange = async (e) => {
    this.sortType = e;

    await this.getTopics();
  };

  @action onSentimentTypeChange = (e) => {
    this.sentimentType = e;
  };

  @action onSentimentChangeComplete = async () => {
    await this.getTopics();
  };

  @action cleanAllFilter = () => {
    this.filterPostType = 'all';
    this.filterWordOfMouth = 'all';
    this.websiteSelectViewModel.onCleanAll();
    this.channelSelectViewModel.onCleanAll();
  };

  @action onLevelSelectedSubmit = async () => {
    try {
      const data = {
        searchKeywordLevel1Ids: this.levelSelectModalViewModel.selectedBrands,
        searchTags: this.levelSelectModalViewModel.selectedSeries,
        searchKeywordLevel3Ids: this.levelSelectModalViewModel.selectedProduct
      };

      await Promise.all([
        TopicService.updateLevels(data),
        this.getSource(),
        this.getTopics()
      ]);

    } catch (error) {
      console.log(error);
    }
  };

  @action onDownload = async () => {
    if (optionsStore.loadingQueue.some((el) => el === 'download')) {
      return;
    }

    optionsStore.setLoading('download');
    this.isDownload = false;

    const userType = userStore.userType;

    try {
      const params = {
        all: true,
        sortBy: this.sortType,
        sentiment: this.sentimentType.join(',')
      };

      const { post } = await TopicService.getDownloadTopics(this.searchId, params, userType);
      runInAction(() => {
        const data = [...post];
        const header = TOPICS_CSV_TITLE_ROW
          // .filter((el) => userType !== 'customer' || el.key !== 'content')
          .map((el) => el.key);
        const workSheet = xlsx.utils.json_to_sheet(data, { header });
        xlsx.utils.sheet_add_aoa(
          workSheet,
          [
            TOPICS_CSV_TITLE_ROW
              // .filter((el) => userType !== 'customer' || el.key !== 'content')
              .map((el) => el.header)],
          { origin: 'A1' }
        );
        workSheet['!cols'] = header.map((_, i) => ({ width: i === 4 || i === 5 ? 50 : 15 }));
        const wordbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wordbook, workSheet, '話題列表');
        xlsx.writeFile(wordbook, `${packageStore.activePackageName}: ${this.datePickerViewModel.currentDate[0].format('YYYY-MM-DD')} ~ ${this.datePickerViewModel.currentDate[1].format('YYYY-MM-DD')}.xlsx`);
      });

      if (userType === 'customer') {
        const { brand, series, product } = this.levelSelectModalViewModel.getSelectedItemTextForDownload();
        await DownloadService.sendDownloadAlert({
          type: 'topic',
          topicsCount: post.length,
          date: `${this.datePickerViewModel.currentDate[0].format('YYYY-MM-DD')}_${this.datePickerViewModel.currentDate[1].format('YYYY-MM-DD')}`,
          filterContent: {
            packageName: packageStore.activePackageName,
            brand,
            series,
            product
          }
        });
      }

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('download');
      });
    }
  };
}
