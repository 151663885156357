import { valueToTextSentimentCT } from 'src/utils';
import { E_WOM_TYPE, SOURCE_CATEGORY_TYPE } from 'src/consts';

export default class DownloadTopicsModel {
  constructor(data, userType) {
    this.post = data.map((el, i) => ({
      order: i + 1,
      title: el.title,
      sentiment: valueToTextSentimentCT(el.sentiment),
      url: el.url,
      category: SOURCE_CATEGORY_TYPE[el.category],
      website: el.website,
      channel: el.channel,
      author: el.author,
      date: el.date,
      content: el.content,
      viewCount: el.viewCount,
      shareCount: el.shareCount,
      commentCount: el.commentCount,
      postType: el.replyId !== 0 ? `回文${el.replyId < 99999 ? el.replyId : ''}` : '主文',
      womType: E_WOM_TYPE[el.womType] ?? E_WOM_TYPE.nature,
      womCount: el.womCount,
      brand: el.hit.level1.name ?? '無',
      series: el.hit.tag.name ?? '無',
      product: el.hit.level3.name ?? '無'

    }));
  }

  static fromRes(data, userType) {
    return new DownloadTopicsModel(data, userType);
  }
}

