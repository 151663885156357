import { makeObservable, observable, action, computed, reaction } from 'mobx';

import packageStore from 'src/stores/packageStore';
import CheckItemViewModel from 'src/components/PackagesLevelSelectModal/CheckItem/viewModel';
import CheckGroupItemViewModel from 'src/components/PackagesLevelSelectModal/CheckGroupItem/viewModel';

export default class PackageLevelSelectModalViewModel {
  @observable isModalOpen = false;
  @observable projectId = '';

  @observable brand = [];
  @observable brandKeyword = '';
  @observable brandKeywordCache = '';

  @observable series = [];
  @observable seriesKeyword = '';

  @observable product = [];
  @observable productKeyword = '';

  // > brand computed

  @computed get brandIndeterminate() {
    return this.searchBrand.some((el) => el.checkedCache) && !this.isAllBrandChecked;
  }

  @computed get isAllBrandChecked() {
    return this.searchBrand.every((el) => el.checkedCache);
  }

  @computed get selectedBrands() {
    // TODO: 優化
    return this.brand.filter((el) => el.checkedCache).map((el) => el.id);
  }

  @computed get searchBrand() {
    return this.brand.filter((el) => el.name.toLowerCase().includes(this.brandKeyword.toLowerCase()));
  }

  // > series computed

  @computed get selectedSeries() {
    // TODO: 優化
    return this.series.map((el) => el.SelectedWithParent).filter((el) => el.length > 0).flat();
  }

  @computed get showSeries() {
    // TODO: 優化
    return this.series.filter((el) => el.keywordChildren.length > 0);
  }

  @computed get seriesIndeterminate() {
    // TODO: 優化
    return this.showSeries.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isAllSeriesChecked;
  }

  @computed get isAllSeriesChecked() {
    // TODO: 優化
    return this.showSeries.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  // > product computed

  @computed get productIndeterminate() {
    // TODO: 優化
    return this.showProduct.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isAllProductChecked;
  }

  @computed get isAllProductChecked() {
    // TODO: 優化
    return this.showProduct.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  @computed get selectedProduct() {
    // TODO: 優化
    return this.product.map((el) => el.selectedItem).filter((el) => el.length > 0).flat();
  }

  @computed get showProduct() {
    return this.selectedBrands.length > 0 ? this.product.filter((el) => el.keywordChildren.length > 0 && this.selectedBrands.includes(el.id)) : this.product.filter((el) => el.keywordChildren.length > 0);
  }

  @computed get brandText() {
    // TODO: 優化
    const isSelected = this.brand.filter((el) => el.checked).map((el) => el.name);
    return isSelected.length >= 1 ? `「${isSelected.join(', ')}」` : '尚未選擇';
  }

  @computed get seriesText() {
    // TODO: 優化
    const res = this.series.filter((el) => !!el.checkedText);
    return res.length >= 1 ? res.map((el) => el.checkedText).join(', ') : '尚未選擇';
  }

  @computed get productText() {
    // TODO: 優化
    const res = this.product.filter((el) => !!el.checkedText);
    return res.length >= 1 ? res.map((el) => el.checkedText).join(', ') : '尚未選擇';
  }

  @computed get modalWidth() {
    switch (packageStore.opened.filter((el) => el !== 'industry').length) {
      case 2:
        return 776;
      case 3:
        return 1130;
      default:
        return 422;
    }
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = () => {
    if (packageStore.opened.filter((el) => el !== 'industry').length >= 1) {
      const {
        brand,
        series,
        product
      } = packageStore;

      this.brand = brand.map((el) => new CheckItemViewModel(el));
      this.series = series.map((el) => new CheckGroupItemViewModel({ ...el, level: 'series' }, this));
      this.product = product.map((el) => new CheckGroupItemViewModel({ ...el, level: 'product' }, this));
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        (bool) => {
          if (!bool) {
            const {
              brand,
              series,
              product
            } = packageStore;

            this.brand = brand.map((el) => new CheckItemViewModel(el));
            this.series = series.map((el) => new CheckGroupItemViewModel({ ...el, level: 'series' }, this));
            this.product = product.map((el) => new CheckGroupItemViewModel({ ...el, level: 'product' }, this));

            once();
          }
        }
      );
    }
  };

  // > modal related

  @action onModalOpen = () => {
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;

    this.brandKeyword = '';
    this.brandKeywordCache = '';
    this.seriesKeyword = '';
    this.series.forEach((el) => el.setKeyword(this.seriesKeyword));
    this.productKeyword = '';
    this.product.forEach((el) => el.setKeyword(this.productKeyword));
  };

  // > actions related

  @action onAllBrandSelect = () => {
    if (!this.isAllBrandChecked) {
      this.searchBrand.forEach((el) => {
        el.setSelect(true);
      });
    } else {
      this.searchBrand.forEach((el) => {
        el.setSelect(false);
      });
    }
  };

  @action onAllSeriesSelect = () => {
    if (!this.isAllSeriesChecked) {
      this.showSeries.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(true);
        });
      });
    } else {
      this.showSeries.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(false);
        });
      });
    }
  };


  @action onAllProductSelect = () => {
    if (!this.isAllProductChecked) {
      this.showProduct.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(true);
        });
      });
    } else {
      this.showProduct.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(false);
        });
      });
    }
  };

  @action onCleanAll = () => {
    this.brand.forEach((el) => {
      el.setSelect(false);
    });
    this.series.forEach((g) => {
      g.children.forEach((c) => {
        c.setSelect(false);
      });
    });
    this.product.forEach((g) => {
      g.children.forEach((c) => {
        c.setSelect(false);
      });
    });
  };

  @action onSubmit = (callback) => {
    this.brand.forEach((el) => {
      el.confirmSelect();
    });
    this.series.forEach((g) => {
      g.children.forEach((c) => {
        c.confirmSelect();
      });
    });
    this.product.forEach((g) => {
      g.children.forEach((c) => {
        c.confirmSelect();
      });
    });

    callback();

    this.onModalClose();
  };

  @action onClose = () => {
    this.brand.forEach((el) => {
      el.onDisposeSelect();
    });
    this.series.forEach((g) => {
      g.children.forEach((c) => {
        c.onDisposeSelect();
      });
    });
    this.product.forEach((g) => {
      g.children.forEach((c) => {
        c.onDisposeSelect();
      });
    });

    this.onModalClose();
  };

  @action setInit = (data) => {
    const {
      brand,
      series,
      product
    } = data;

    this.brand.forEach((el) => {
      const find = brand.find((b) => el.id === b);
      if (find) {
        el.setInit();
      }
    });

    this.series.forEach((el) => {
      el.children.forEach((s) => {
        const find = series.find((ds) => ds === s.id);
        if (find) {
          s.setInit();
        }
      });
    });

    this.product.forEach((el) => {
      el.children.forEach((p) => {
        const find = product.find((dp) => dp === p.id);
        if (find) {
          p.setInit();
        }
      });
      if (el.selectedItem.length > 0) {
        const target = this.brand.find((b) => b.id === el.id);
        if (target) {
          target.setInit();
        }
      }
    });

  };

  // > brand related
  // > search

  @action onBrandKeywordChange = (e) => {
    this.brandKeywordCache = e.target.value;
    if (!e.target.value.trim()) {
      this.onBrandSearchSubmit();
    }
  };

  @action onBrandSearchSubmit = () => {
    this.brandKeyword = this.brandKeywordCache;
  };

  @action onBrandKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onBrandSearchSubmit();
    }
  };

  // > series related
  // > search

  @action onSeriesKeywordChange = (e) => {
    this.seriesKeyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onSeriesSearchSubmit();
    }
  };

  @action onSeriesSearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.series.forEach((el) => el.setKeyword(this.seriesKeyword));
  };

  @action onSeriesKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSeriesSearchSubmit();
    }
  };


  // > product related
  // > search

  @action onProductKeywordChange = (e) => {
    this.productKeyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onProductSearchSubmit();
    }
  };

  @action onProductSearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.product.forEach((el) => el.setKeyword(this.productKeyword));
  };

  @action onProductKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onProductSearchSubmit();
    }
  };

  @action getSelectedItemTextForDownload = () => {
    const selectedBrand = this.brand.filter((el) => el.checked).map((el) => el.name);
    const seriesRes = this.series.filter((el) => !!el.checkedText).map((el) => el.checkedText);
    const productRes = this.product.filter((el) => !!el.checkedText).map((el) => el.checkedText);
    return {
      brand: selectedBrand || [],
      series: seriesRes || [],
      product: productRes || []
    };
  };
}
